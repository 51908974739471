import { FC, forwardRef } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import { Capitalized, Small, Tiny2 } from '@/components/atoms/typography'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { BigBadge } from '@/components/atoms/badge'
import { validFor } from '@/theme/validFor'

type IconButtonProps = {
  title: string
  onClick?: (e: Event) => void
  IconElement: JSX.Element
  AfterIconElement?: JSX.Element
  TextElement?: JSX.Element
  titleMarginY?: number
  titleColor?: string
} & Rest

export const IconButton: FC<IconButtonProps> = ({
  IconElement,
  title,
  TextElement = Tiny2,
  onClick,
  ...rest
}) => (
  <ButtonWrapper onClick={makeDefaultOnClick(onClick)} {...rest}>
    {IconElement}
    <TextElement ml={14}>{title}</TextElement>
  </ButtonWrapper>
)

type IconButtonReverseProps = {
  title: string
  onClick?: (e: Event) => void
  IconElement: JSX.Element
  AfterIconElement?: JSX.Element
  TextElement?: JSX.Element
  titleMarginY?: number
  titleColor?: string
} & Rest

export const IconButtonReverse: FC<IconButtonReverseProps> = ({
  IconElement,
  title,
  TextElement = Tiny2,
  onClick,
  ...rest
}) => (
  <ButtonWrapperReverse onClick={makeDefaultOnClick(onClick)} {...rest}>
    <TextElement ml={18}>{title}</TextElement>
    <Box mr={26}>{IconElement}</Box>
  </ButtonWrapperReverse>
)

type IconButtonWithBadgeProps = {
  badgeCount: number
} & IconButtonProps

export const IconButtonWithBadge: FC<IconButtonWithBadgeProps> = ({
  IconElement,
  title,
  badgeCount,
  onClick,
  ...rest
}) => (
  <IconButtonWithBadgeWrapper onClick={makeDefaultOnClick(onClick)} {...rest}>
    <Flex>
      {IconElement}
      <StyledIconButtonWithBadgeText ml={14}>
        {title}
      </StyledIconButtonWithBadgeText>
    </Flex>
    {badgeCount && badgeCount > 0 ? <BigBadge counter={badgeCount} /> : null}
  </IconButtonWithBadgeWrapper>
)

export const IconButtonCapitalized: FC<IconButtonProps> = ({
  IconElement,
  title,
  onClick,
  ...rest
}) => (
  <ButtonWrapper onClick={makeDefaultOnClick(onClick)} {...rest}>
    {IconElement}
    {title && <Capitalized ml={11}>{title}</Capitalized>}
  </ButtonWrapper>
)

export enum SmallNonBorderIconButtonType {
  semiTransparentText,
  normal
}

type SmallNonBorderIconButtonProps = {
  type?: SmallNonBorderIconButtonType
} & IconButtonProps

export const SmallNonBorderIconButton: FC<SmallNonBorderIconButtonProps> = ({
  IconElement,
  title,
  type = SmallNonBorderIconButtonType.normal,
  onClick,
  ...rest
}) => (
  <NonBorderIconButtonWrapper
    onClick={makeDefaultOnClick(onClick)}
    border="none"
    {...rest}
  >
    {IconElement}
    {title && (
      <Tiny2
        opacity={type === SmallNonBorderIconButtonType.normal ? 1 : 0.5}
        ml="6px"
        my={0}
      >
        {title}
      </Tiny2>
    )}
  </NonBorderIconButtonWrapper>
)

export const IconButtonOnInvertedBackground = forwardRef<
  Record<string, never>,
  IconButtonProps
>(
  (
    {
      IconElement,
      AfterIconElement,
      title,
      onClick,
      titleMarginY,
      titleColor,
      ...rest
    },
    ref
  ) => (
    <InvertedButtonWrapper
      onClick={makeDefaultOnClick(onClick)}
      ref={ref}
      {...rest}
    >
      {IconElement}
      <Small my={titleMarginY} pl={11} color={titleColor}>
        {title}
      </Small>
      {AfterIconElement && AfterIconElement}
    </InvertedButtonWrapper>
  )
)

const makeDefaultOnClick =
  (onClick?: (e: Event) => void): ((e: Event) => void) =>
  (e) => {
    if (onClick) {
      e.preventDefault()
      onClick(e)
    }
  }

const ButtonWrapper = styled(Flex)`
  border: 1px solid ${(props) => props.theme.colors.onBackground};
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      transparentize(0.97, props.theme.colors.onBackground)};
  }
`
const ButtonWrapperReverse = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      transparentize(0.97, props.theme.colors.onBackground)};
  }
`

const InvertedButtonWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.background};
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.buttonBorderRadius}px;
  color: ${(props) => props.theme.colors.onBackground};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.onBackground};
    background-color: ${(props) =>
      transparentize(0.03, props.theme.colors.background)};
  }
`

const IconButtonWithBadgeWrapper = styled(ButtonWrapper)`
  justify-content: space-between;
`

const StyledIconButtonWithBadgeText = styled(Tiny2)`
  ${validFor.tablet`
    font-size: 16px;
  `}
`

const NonBorderIconButtonWrapper = styled(ButtonWrapper)`
  border: none;
`
