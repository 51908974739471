import { FC } from 'react'
import { Flex } from '@/components/atoms/Grid'
import { Tiny } from '@/components/atoms/typography'
import styled, { css } from 'styled-components'
import { validFor } from '@/theme/validFor'

type BadgeProps = {
  counter: number
} & Rest

const Badge: FC<BadgeProps> = ({ counter, ...rest }) => {
  return (
    <StyledFlex data-cy="badge" {...rest}>
      <StyledBadgeText>{counter}</StyledBadgeText>
    </StyledFlex>
  )
}

export const BigBadge: FC<BadgeProps> = ({ counter, ...rest }) => {
  return (
    <StyledBigBageWrapper data-cy="big-badge" {...rest}>
      <StyledBadgeText>{counter}</StyledBadgeText>
    </StyledBigBageWrapper>
  )
}

export default Badge

const WrapperBase = css`
  justify-content: center;
  align-items: center;
  min-width: 19px;
  height: 19px;
  border-radius: 19px;
  ${validFor.tablet` 
    min-width: 15px;
    height: 15px;
    border-radius: 15px; 
	`};
  ${validFor.mobile` 
    min-width: 13px;
    height: 13px;
    border-radius: 13px;  
	`};
  background: ${(props) => props.theme.colors.accent};
`

const StyledFlex = styled(Flex)`
  ${WrapperBase};
  padding: 0;
  ${validFor.tablet`
    border: 1px solid ${(props) => props.theme.colors.background};
  `}
  ${validFor.mobile` 
    border: 1px solid ${(props) => props.theme.colors.onBackground};
	`};
`

const StyledBigBageWrapper = styled(Flex)`
  ${WrapperBase};
  padding: 3px;
`

const StyledBadgeText = styled(Tiny)`
  margin: 0;
  color: white;
  justify-self: center;
  line-height: normal;
  text-align: center;
  align-self: center;
  margin-block-start: 0;
  margin-block-end: 0;
  ${validFor.tablet` 
    font-size: 9.6px;
	`};
  ${validFor.mobile` 
    font-size: 9.6px;
	`};
`
