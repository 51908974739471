import { FC } from 'react'
import { SvgProps } from './svgProps'

const ChristmasGiftBoxIcon: FC<SvgProps> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color || '#595959'}
    width={width || 17}
    height={height || 17}
    viewBox="0 0 24 24"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <path
        fill="none"
        stroke={color || '#595959'}
        strokeWidth="2"
        d="M3,11 L21,11 L21,23 L3,23 L3,11 Z M2,11 L2,7 L22,7 L22,11 L2,11 Z M12,23 L12,7 L12,23 Z M7,7 L12,7 C12,7 10,2 7,2 C3.5,2 3,7 7,7 Z M17.1843819,7 L12.1843819,7 C12.1843819,7 14,2 17.1843819,2 C20.5,2 21.1843819,7 17.1843819,7 Z"
      />
    </g>
  </svg>
)

export default ChristmasGiftBoxIcon
