import { FC } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import { Body2, Small2 } from '@/components/atoms/typography'
import { validFor } from '@/theme/validFor'
import { MenuCloseIconDefault } from '../atoms/icons/MenuCloseIconDefault'

type ModalProps = {
  title?: string
  description?: string | JSX.Element | JSX.Element[]
  centerDescription?: boolean
  onDidTapClose: () => void
  contentProps?: Rest
  HeaderElement?: () => JSX.Element
  wrapperProps?: Rest
  hideCrossIcon?: boolean
  isPpl?: boolean
} & Rest

const Modal: FC<ModalProps> = ({
  title,
  description,
  centerDescription = false,
  onDidTapClose,
  HeaderElement,
  contentProps,
  wrapperProps,
  children,
  hideCrossIcon = false,
  isPpl = false,
  ...rest
}) => {
  const onCloseModal = (isPpl: boolean) => () => {
    !isPpl && onDidTapClose()
  }
  const onStopPropagation = (isPpl: boolean) => (e: Event) => {
    !isPpl && e.stopPropagation()
  }

  return (
    <ModalWrapper onClick={onCloseModal(isPpl)} {...wrapperProps}>
      <ContentWrapper onClick={onStopPropagation(isPpl)} {...rest}>
        {HeaderElement && <HeaderElement />}
        <Box position="relative">
          {!hideCrossIcon && (
            <CloseIcon onClick={onDidTapClose} cursorOnHover={true} />
          )}
        </Box>
        <Box
          name="modalContentBox"
          py={18}
          px={{ desktop: 73, tablet: 73, mobile: 14 }}
          {...contentProps}
        >
          {title && (
            <Body2
              textAlign="center"
              px={{ desktop: 0, tablet: 0, mobile: 28 }}
              my={0}
            >
              {title}
            </Body2>
          )}
          {description && (
            <StyledSmall2 center={centerDescription} maxWidth={800}>
              {description}
            </StyledSmall2>
          )}
          {children}
        </Box>
      </ContentWrapper>
    </ModalWrapper>
  )
}

export default Modal

export const FixedWidthModal = styled(Modal)`
  width: 800px;
  && {
    overflow: auto;
    padding: 18px;
  }
`

const CloseIcon = styled(MenuCloseIconDefault)`
  position: absolute;
  right: 14px;
  top: 18px;
  z-index: 1000;
`

const ModalWrapper = styled(Flex)`
  flex-direction: column;
  position: fixed;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.54);
  z-index: 100;
  justify-content: center;
  ${({ visibility }) => visibility && `visibility: ${visibility};`};
`

const ContentWrapper = styled(Box)`
  position: relative;
  max-width: ${(props) => props.maxWidth ?? '70%'};
  max-height: ${(props) => props.maxHeight ?? '70%'};
  background-color: ${(props) => props.theme.colors.background};
  overflow: auto;
  ${validFor.mobile`
    max-width: 60%;
  `}
`

const StyledSmall2 = styled(Small2)`
  ${({ center }) => (center ? 'text-align: center' : '')}
`
